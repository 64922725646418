<template>
  <div>
    <div class="row align-items-stretch">
      <div
        v-for="(project, index) in projects"
        :key="index++"
        class="col-4 py-3"
      >
        <div class="card shadow border-0 p-0 h-100">
          <div class="img-card-top h-65 rounded-top">
            <img :src="project.logo" class="rounded-top w-100 h-100" />
          </div>
          <div class="card-body h-35">
            <div class="h-75">
              <div class="fw-bold">
                {{ project.title }}
              </div>
            </div>

            <div class="d-flex justify-content-between h-25">
              <button
                @click="
                  $router.push({
                    name: 'projects-details',
                    params: { slug: project.slug },
                  })
                "
                class="btn btn-outline-warning"
              >
                Afficher
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  beforeCreate() {
    this.$store.dispatch("project/fetch");
  },
  computed: {
    ...mapGetters("project", { projects: "getProjects" }),
  },
};
</script>
